import React, {useEffect, useState} from "react";
import BodyContainer from "../Common/BodyContainer/BodyContainer";
import CustomButton from "../Common/CustomButton/CustomButton";
import {useHistory} from 'react-router-dom';
import {carBrands} from "../../AppConstants";


export default function (props) {
	const history = useHistory();
	const renderLocation = (permalink)=>{
		props.reactGA.event({
			category: "Location",
			action: "Select",
			label:permalink
		})
		history.push(`/${props.brand.link}/${props.brandCondition}/${permalink}`);
	}
	const [images,setImages] = useState([]);

	useEffect(()=>{
		let newImages = [];
			let width = props.brand.locations.length * 10
			if(props.brand.locations.length === 1){
				width = '50'
			}
			props.brand.locations.map(item=>{
				newImages.push({
					url: item.image,
					title: item.name,
					width:`${width}%`,
					permalink:item.permalink,
					renderFunction : renderLocation
				})
				return item;
			})

		setImages(newImages);
	},[props.brand.locations])



	return(<React.Fragment>
		<BodyContainer {...props} childComponent={<React.Fragment>
			<React.Fragment>
				<div className={'col'}>
					<h4 style={{fontSize:'16px',fontFamily:`${props.brand.name === carBrands.jaguar.title ? 'Jaguar':'LandRover'}`}}>Waar word je graag verder geholpen?</h4>
				</div>
				<CustomButton {...props} carCondition={props.brandCondition} className={'margin-images'}  images={images}/>
			</React.Fragment>
		</React.Fragment>
		} />
	</React.Fragment>)
}
