import React, {useEffect} from "react";
import {carBrands} from "../../../AppConstants";
import {useHistory} from "react-router-dom";
import useMediaQuery from "@material-ui/core/useMediaQuery";
export default function (props) {
const history = useHistory();
  const isMobile = useMediaQuery('(max-width:769px)');

    return(
        <header style={{backgroundColor:'white'}} id={'header'} >
            <div className={"container"}>
             <div className={"row"}>
            <div style={{height:'55px',cursor:'pointer',display:'flex',justifyContent:'space-between'}} className={"col-lg-12 logo"}>
              {props.brand && !isMobile &&  <img  style={{maxHeight:'100%',float:'right',objectFit:'contain',width:`${isMobile && (props.brand.name.toLowerCase() === carBrands.jaguar.name) ? '26%':'11rem'}`}} alt={`${props.brand.name} not found`}  src={props.brand.image}/>}
                <img onClick={()=>{history.push('/')}} className={'metropool'} alt="Metropool" src="/assets/img/headerimage.png"/>
            </div>

        </div>
            </div>
        </header>
    )
}
