import React, {useEffect, useState} from "react";
import {carBrands} from "../../AppConstants";
import BodyContainer from "../Common/BodyContainer/BodyContainer";
import { useHistory } from "react-router-dom";
import CustomButton from "../Common/CustomButton/CustomButton";

export default function (props) {
    const history = useHistory();
    const renderBrandCars = (carType)=>{
        props.reactGA.event({
            category: "Appointment Type",
            action: "Select",
            label:carType
        })
        history.push(`${props.brand.link}/${carType}`,{carCondition:carType});
    }
    const [images,setImages] = useState([]);


    useEffect(()=>{
        setImages([
            {    url: props.brand.new_background_image,
                title: 'New',
                width: '40%',
                permalink:'new',
                renderFunction:renderBrandCars
            },
            {
                url: props.brand.used_background_image,
                title: 'Used',
                width: '40%',
                permalink:'used',
                renderFunction:renderBrandCars
            },
        ]);
    },[props.brand])
    useEffect(()=>{
        if(props.brand.title === carBrands.jaguar.title){
            props.switchTheme()
        }
    },[props.switchTheme])

    return(
    <React.Fragment>
        <BodyContainer {...props} childComponent={
            <React.Fragment>
                <div className={'col'}>
                    <h4 style={{fontFamily:`${props.brand.name === carBrands.jaguar.title ? 'Jaguar':'LandRover'}`}}>Maak een keuze</h4>
                </div>
                <CustomButton {...props}  className={`margin-images`}  images={images}/>
            </React.Fragment>
        }/>
    </React.Fragment>
    )
}
