import React, {useEffect, useState} from "react";
import {useHistory} from 'react-router-dom';
import {CSSTransition} from "react-transition-group";
import Button from "@material-ui/core/Button";
import {carBrands} from "../../AppConstants";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import SimpleModal from "../Common/Utilities/SimpleModal";
import PhoneIcon from "@material-ui/icons/Phone";
import MailIcon from "@material-ui/icons/Mail";

export default function (props) {
  const history = useHistory();
  useEffect(()=>{
    setBrands(props.brands);
    if(props.brands.length > 0){
      setLoaderVisibility(false);
    }
  },[props.brands])
  const [loader,setLoaderVisibility] = useState(true);
  const [brands,setBrands] = useState([]);
  const isMobile = useMediaQuery('(max-width:769px)');


  return(<React.Fragment>
       <div style={{height:'100vh'}}  className={"white"}>
         <div style={{display:'flex',justifyContent:'center',alignItems:'center'}}>  <img style={{maxWidth:'320px',paddingTop:'35px'}} onClick={()=>{history.push('/')}} className={'metropool'} alt="Metropool" src="/assets/img/headerimage.png"/></div>
         <div >
           <div className={"row"}>
             {brands.length !== 0 ?
                <div className={"col-lg-12 col-md-12 col-sm-12"}>
                  <CSSTransition
                     in={!loader }
                     timeout={900}
                     classNames="list-transition"
                     appear
                     unmountOnExit
                  >
                    <div style={{height:"100vh"}} key={props.brand}  className={"row"}>
                      {brands.map(item=>{
                        return<React.Fragment>
                          <div style={{position:'relative'}}  key={item.name} className={'col-lg-6 col-md-6 col-sm-12'}>
                            <div style={{height:isMobile? '200px':'230px',zIndex:'2'}} className={'logo-container'}>
                              <div style={{height:isMobile?'100px':'150px',zIndex:'2'}} className={'logo-container-image'}>
                                <img   alt={item.name} src={item.image} />
                              </div>
                              <Button className={`${item.name.split(' ').join('').toLowerCase()}-button`} onClick={()=>{
                                props.reactGA.event({
                                  category: "Brand",
                                  action: "Select",
                                  label: item.name.toLowerCase()
                                })
                                history.push(`/${item.permalink.toLowerCase()}`)}} style={{ fontFamily :`${item.name === carBrands.jaguar.title ? 'Jaguar' :'LandRover'}`,cursor:'pointer',zIndex:'2'}}>Maak afspraak</Button>
                            </div>
                            {/* <img style={{height:isMobile?'50vh':'100vh'}} className={'brandImage'} src={item.brand_background_image}/>*/}
                          </div>
                        </React.Fragment>
                      })}

                    </div>
                  </CSSTransition>
                  <div  style={{color:'white',display:'flex',justifyContent:'space-around',alignItems:'center',marginBottom:'20px'}}>
                    <div style={{display:'flex',flexDirection:'column'}}>
                      <div>
                        <p>JAGUAR LAND ROVER KEMPEN</p>
                        <p>Herenthoutseweg 235 ,2200 HERENTALS</p>
                      </div>
                      <div style={{marginLeft:'10px'}}>
                        <div  className={'flex'}><PhoneIcon style={{marginRight:'10px'}}/> <a style={{color:'white'}} href={`tel:${'014211373'.replace(/\\s/g, '')}`}>+ 32(0)1 421 13 73</a></div>
                        <div style={{marginTop:'10px'}} className={'flex'}><MailIcon style={{marginRight:'10px'}}/><a style={{color:'white'}} href={`mailto:info@metropool-kempen.be`}>info@metropool-kempen.be</a></div>
                      </div>
                    </div>
                    <div style={{display:'flex',flexDirection:'column'}}>
                      <div>
                        <p>JAGUAR LAND ROVER METROPOOL NOORD</p>
                        <p>Bredabaan 1165-1171,2900 SCHOTEN</p>
                      </div>
                      <div style={{marginLeft:'10px'}}>
                        <div  className={'flex'}><PhoneIcon style={{marginRight:'10px'}}/> <a style={{color:'white'}} href={`tel:${'036416590'.replace(/\\s/g, '')}`}>+ 32(0)3 641 65 90</a></div>
                        <div style={{marginTop:'10px'}} className={'flex'}><MailIcon style={{marginRight:'10px'}}/><a style={{color:'white'}} href={`mailto:info@metropool-noord.be`}>info@metropool-noord.be</a></div>
                      </div>
                    </div>
                    <div style={{display:'flex',flexDirection:'column'}}>
                      <div>
                        <p>JAGUAR LAND ROVER METROPOOL ZUID</p>
                        <p>BOOMSESTEENWEG 427,2610 WILRIJK</p>
                      </div>
                      <div style={{marginLeft:'10px'}}>
                        <div  className={'flex'}><PhoneIcon style={{marginRight:'10px'}}/> <a style={{color:'white'}} href={`tel:${'036083131'.replace(/\\s/g, '')}`}>+ 32(0)3 608 31 31</a></div>
                        <div style={{marginTop:'10px'}} className={'flex'}><MailIcon style={{marginRight:'10px'}}/><a style={{color:'white'}} href={`mailto:iinfo@metropool-zuid.be`}>info@metropool-zuid.be</a></div>
                      </div>
                    </div>

                  </div>
                </div>: ''}
           </div>
         </div>
       </div>
       { props.showHomePageText == "true" &&  props.homePageText != null &&  <SimpleModal text={props.homePageText} showModal={true}/>}
     </React.Fragment>
  )
}
