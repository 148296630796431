import React from "react";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Link from "@material-ui/core/Link";
import HomeIcon from "@material-ui/icons/Home";
import DriveEtaSharpIcon from "@material-ui/icons/DriveEta";
import {useHistory} from 'react-router-dom';
import FiberNewIcon from '@material-ui/icons/FiberNew';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import CommuteIcon from '@material-ui/icons/Commute';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import {carBrands} from "../../../AppConstants";
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import NoteIcon from '@material-ui/icons/Note';
import VideocamIcon from '@material-ui/icons/Videocam';
export default  function (props) {
	const history = useHistory();
	const links = history.location.pathname.substr(1,).split("/");

	return (
		 <div className={"brand-container"}>
			 <div style={{backgroundImage:`url(${props.brand.brand_header_image})`}} className={`brand-banner`}>
			 </div>
			 <div className={'container container-middle'}>
				 <div className={'row'}>
					 <div className={'col-lg-12 col-md-12 col-sm-12'}>
						 <Breadcrumbs style={{paddingTop:'10px'}}  separator={<NavigateNextIcon style={{color:`${props.brand.name === carBrands.jaguar.title ? '#9e1b32' :'#000000'}`}} fontSize="small" />} aria-label="breadcrumb">
							 <Link color="inherit" style={{fontFamily:`${props.brand.name === carBrands.jaguar.title ? 'Jaguar':'LandRover'}`,color:`${props.brand.name === carBrands.jaguar.title ? '#9e1b32' :'#000000'}`}}  onClick={()=>{history.push(`/`)}}  className={`link anchorTag`}>
								 <HomeIcon className={'icon'} />
								 Metropool
							 </Link>
							 {links.map((item,index)=>{
							 	if(index === 0){
									return  <Link style={{fontFamily:`${props.brand.name === carBrands.jaguar.title ? 'Jaguar':'LandRover'}`,color:`${links.length === index + 1 ?'unset': `${props.brand.name === carBrands.jaguar.title ? '#9e1b32' :'#000000'}`}`}}  key={item-index}   color="inherit" onClick={()=>{
										if(index+1 === links.length){
											return;
										}
										history.push(`/${props.brand.link}`)}}  className={`link ${index+1 !== links.length ? 'anchorTag' :''}`}>
										<DriveEtaSharpIcon className={'icon'}/>
										{props.brand.name}
									</Link>
								}
							 	if(index === 1){
									return  <Link  style={{fontFamily:`${props.brand.name === carBrands.jaguar.title ? 'Jaguar':'LandRover'}`,color:`${links.length === index + 1 ?'unset': `${props.brand.name === carBrands.jaguar.title ? '#9e1b32' :'#000000'}`}`}} key={item-index}  color="inherit" onClick={()=>{
										if(index+1 === links.length){
											return;
										}
										history.push(`/${props.brand.link}/${item}`)}}  className={`link ${index+1 !== links.length ? 'anchorTag' :''}`}>
										{item === "new" ? <FiberNewIcon className={'icon'}/> : item === "used" ? <AutorenewIcon className={'icon'}/> : <CommuteIcon className={'icon'}/> }
										{item.charAt(0).toUpperCase()+ item.slice(1)}
									</Link>
								}
							 	if(index === 2 && props.location){
								return 	<Link style={{fontFamily:`${props.brand.name === carBrands.jaguar.title ? 'Jaguar':'LandRover'}`,color:`${links.length === index + 1 ?'unset': `${props.brand.name === carBrands.jaguar.title ? '#9e1b32' :'#000000'}`}`}}   key={item-index}  color="inherit"
										onClick={()=>{
									if(index+1 === links.length){
										return;
									}
									history.push(`/${props.brand.link}/${props.brand.condition}/${item}`)}}
									className={`link ${index+1 !== links.length ? 'anchorTag' :''}`}>
									{props.location.permalink.includes("videocall") ?<VideocamIcon className={'icon'}/> :	<LocationOnIcon className={'icon'}/>}
										{props.location.name}
									</Link>
								}
							 	if(index === 4){
									return <Link style={{fontFamily:`${props.brand.name === carBrands.jaguar.title ? 'Jaguar':'LandRover'}`,color:`${links.length === index + 1 ?'unset': `${props.brand.name === carBrands.jaguar.title ? '#9e1b32' :'#000000'}`}`}}   key={item-index} color="inherit" onClick={()=>{
										if(index+1 === links.length){
											return;
										}
										history.push(`/${props.brand.link}/${props.brand.condition}/${props.location.permalink}/${item}`)}}  className={`link ${index+1 !== links.length ? 'anchorTag' :''}`}>
										<NoteIcon className={'icon'}/>
										Form
									</Link>
								}
							 	return <Link   style={{fontFamily:`${props.brand.name === carBrands.jaguar.title ? 'Jaguar':'LandRover'}`,color:`${links.length === index + 1 ?'unset': `${props.brand.name === carBrands.jaguar.title ? '#9e1b32' :'#000000'}`}`}}  key={item-index} color="inherit" onClick={()=>{
									if(index+1 === links.length){
										return;
									}

							 		history.push(`/${props.brand.link}/${props.brand.condition}/${props.location.permalink}/${item}`)}}  className={`link ${index+1 !== links.length ? 'anchorTag' :''}`}>
									<DriveEtaSharpIcon className={'icon'}/>
									{item}
								</Link>

							 })}
						 </Breadcrumbs>
					 </div>
				 </div>
				 <div style={{paddingTop:'10px'}} className={'row'}>
					 {props.childComponent}
				 </div>
			 </div>

		 </div>
	)
}
