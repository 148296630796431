import React, {useEffect, useState} from 'react';
import './App.scss';
import  {
  Redirect,
  BrowserRouter ,
  Switch,
  Route,
} from "react-router-dom";
import HomePage from "./Components/HomePage/HomePage";
import NotFound from "./Components/NotFound/NotFound";
import Header from "./Components/Common/Header/Header";
import Footer from "./Components/Common/Footer/Footer";
import Brand from "./Components/Brand/Brand";
import { ThemeProvider } from 'styled-components';
import { lightTheme, darkTheme } from './theme';
import { GlobalStyles } from './global';
import BookingSystem from "./Components/BookingSystem/BookingSystem";
import {getBrands, getFooterData} from "./Components/HTTPS/apisInterface";
import Form from "./Components/Form/Form";
import ReactPixel from 'react-facebook-pixel';
import NewUsedCar from "./Components/NewUsedCar/NewUsedCar";
import ReactGA from 'react-ga';

function App() {
  const options = {
    autoConfig: true, // set pixel's autoConfig
    debug: false, // enable logs
  };

  const [theme, setTheme] = useState('light');
  const [brands,setBrands] = useState([]);
  const [homePageText,setHomePageText] = useState(null);
  const [currentBrand,setCurrentBrand] = useState(null);
  const [footerBrandData,setFooterBrandData]= useState(null);
  const [isHomePage,setHomePage]=useState(true);
  const [showHomePageText,setShowHomePageText] = useState(null);
  const [isRedirectURL,setIsRedirectUrl] = useState(null);

  useEffect(()=>{
    getBrands().then(res=>{
      ReactPixel.init('226381818882123', {em:"christina@metropool.be"}, options);
      ReactGA.initialize('UA-150377924-2');
      setBrands(res.brands);
      setHomePageText(res.home_page_text);
      setShowHomePageText(res.show_text);
    })
  },[])
  const getCurrentBrandData = (location,locationSearch = null)=>{
    setHomePage(false);
    location = location.substr(1,).split('/');
    setIsRedirectUrl(new URLSearchParams(locationSearch).get('car_url'))
    const currentBrand = brands.filter(item=>location[0] === item.permalink.toLowerCase());

    if(currentBrand.length > 0){
      currentBrand[0].condition = location[1];
      currentBrand[0].link = currentBrand[0].permalink.toLowerCase();

      if(location.length > 3){
        currentBrand[0].currentCarName = location[3];
      }

      if(location.length >= 3){
        let currentExactLocation = currentBrand[0].locations.filter(item=>{
          return item.permalink === location[2];
        })
        if(currentExactLocation.length === 0 ){
          return null;
        }
        currentBrand[0].currentLocation = currentExactLocation[0];
        currentBrand[0].currentLocationFullName = currentExactLocation[0].name;
      }
      setCurrentBrand(currentBrand[0]);
      if(footerBrandData === null){
        getFooterData(currentBrand[0].name).then(response=>{
          setFooterBrandData(response);
        });
      }

      return currentBrand[0];
    }
    else{
      setCurrentBrand(null);
      return null;
    }

  }

  return (
     <React.Fragment>
       {brands.length > 0 ?  <ThemeProvider theme={theme === 'light' ? lightTheme : darkTheme}>
         <GlobalStyles />
         <BrowserRouter>
           {!isHomePage && <Header brand={currentBrand}/>}
           <Route
              render={({location})=>{
                setTheme('light')
                return (
                   <Switch location={location}>
                     <Route exact path="/" render={({location})=>{
                       setHomePage(true);
                       setFooterBrandData(null);
                       ReactPixel.track("Home Page View",{});
                       ReactGA.pageview("/");
                       return <HomePage reactGA={ReactGA} showHomePageText={showHomePageText} homePageText={homePageText} brands={brands}/>;
                     }}>
                     </Route>
                     <Route  exact path="/:brandType" render={ ({location})=>{
                       const brand = getCurrentBrandData(location.pathname,location.search);
                       if(brand === null) return <NotFound/> ;
                       ReactGA.pageview(`/${brand.name}`);
                       return <Brand reactGA={ReactGA} switchTheme={setTheme} brand={brand} />;
                     }}>
                     </Route>
                     <Route  exact path="/:brandType/:brandCondition" render={ ({location})=>{
                       const brand = getCurrentBrandData(location.pathname,location.search);
                       if(brand === null || brand === undefined) return  <NotFound/>;
                       if(brand.condition === "new" || brand.condition === "used"){
                         ReactGA.pageview(`/${brand.name}/${brand.condition}`);
                         return <NewUsedCar reactGA={ReactGA}  brandCondition={brand.condition}  brand={brand}/>;
                       }else {
                         return <NotFound/>;
                       }
                     }}/>
                     <Route  exact path="/:brandType/:brandCondition/:brandLocation" render={ ({location})=>{
                       const brand = getCurrentBrandData(location.pathname,location.search);
                       if(brand === null || brand === undefined){
                         return <NotFound/>;
                       }

                       ReactPixel.track("Car Booking Page View",{});
                       ReactGA.pageview(`/${brand.name}/${brand.condition}/${brand.currentLocation.name}`);
                       return <BookingSystem reactGA={ReactGA} brandCondition={brand.condition}  location={brand.currentLocation} brand={brand} />
                     }}/>

                     <Route  exact path="/:brandType/:brandCondition/:brandLocation/booking" render={ ({location})=>{
                       const brand = getCurrentBrandData(location.pathname,location.search);
                       if(brand === null || brand === undefined) return <NotFound/>;
                       if(location.state === undefined) {return <NotFound/>;}
                       ReactPixel.track("Form Page View",{});
                       ReactGA.pageview(`/${brand.name}/${brand.condition}/${brand.currentLocation.name}/form`);
                       return <Form reactGA={ReactGA} urlSearchParam={isRedirectURL} brandCondition={brand.condition} {...location.state}/>
                     }}/>
                     <Route path="*" component={NotFound}/>

                   </Switch>)
              }}
           />
           {footerBrandData &&  <Footer socialMedia={currentBrand} brand={footerBrandData} theme={theme}/>}
         </BrowserRouter>
       </ThemeProvider>:''}
     </React.Fragment>
  );
}

export default App;
