import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Markup from "interweave";
import CancelIcon from '@material-ui/icons/Cancel';
import {Button} from "@material-ui/core";

function rand() {
	return Math.round(Math.random() * 20) - 10;
}


function getModalStyle() {
	const top = 50 + rand();
	const left = 50 + rand();

	return {
		top: `50%`,
		left: `50%`,
		transform: `translate(-${top}%, -${left}%)`,
	};
}

const useStyles = makeStyles((theme) => ({
	paper: {
		position: 'absolute',
		width: '80%',
		backgroundColor: theme.palette.background.paper,
		border: '2px solid #000',
		boxShadow: theme.shadows[5],
		padding: theme.spacing(2, 4, 3),
	},
}));

export default function SimpleModal(props) {
	const classes = useStyles();
	// getModalStyle is not a pure function, we roll the style only on the first render
	const [modalStyle] = React.useState(getModalStyle);
	const [open, setOpen] = React.useState(props.showModal ? props.showModal : false);

	const handleOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	const body = (
		 <div style={modalStyle} className={classes.paper}>
				<div>
					<Markup id="simple-modal-description" content= {props.text} />
					<div style={{display:'flex'}}><Button onClick={()=>{setOpen(false)}} style={{marginLeft:'auto',marginRight:'auto'}}><CancelIcon/>Close</Button></div>
				</div>
			 <SimpleModal />
		 </div>
	);

	return (
		 <div>
			 <Modal
					open={open}
					onClose={handleClose}
					aria-labelledby="simple-modal-title"
					aria-describedby="simple-modal-description"
			 >
				 {body}
			 </Modal>

		 </div>
	);
}
